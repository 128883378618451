<template>
  <div>
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-5/6 mx-auto">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 nrounded-t">
            <h3 class="text-3xl font-semibold text-sitepurple">Game State Console</h3>
            <button type="button" @click="closeModal">
              <svg class="w-8 h-8  text-red-500  hover:bg-red-500 hover:text-white font-bold text-sm rounded" 
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                clip-rule="evenodd"/>
              </svg>            
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <!-- IF (addingPredefinedGameState = false) -->
            
            <div v-if="!addingPredefinedGameState">

              <!--  ........................................         1          ........................................................... -->
              <div v-if="editingState == false">
                <!-- GET STATE -->
                <div class="flex gap-4 itens-center text-white">
                  <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                        type="button"
                        @click="getState">
                    Get State
                  </button>
                </div>
                <!-- CODE -->
                <div clas="w-full max-height-limiter">
                  <copy-to-clipboard class="cursor-pointer text-blue-600 hover:text-blue-900" 
                        :copy="JSON.stringify(this.gameState, null, 2)">
                    Copy 
                  </copy-to-clipboard>
                  <code-highlight class="w-full overflow-auto max-height-limiter" language="json">
                    {{ gameState }} 
                  </code-highlight >
                </div>
                
              </div>

              <!-- IF (editingState = true) -->
              <div v-else >
                <!--  ........................................         2          ........................................................... -->
                <!-- STATE -->
                <form class="w-full flex flex-wrap">
                  <div class="w-full px-3">
                    <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="availableStateInput">
                      State
                    </label>
                    <div class="relative">
                      <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            name="availableStateInput"
                            id="availableStateInput"
                            v-model="selectedPredefinedGameSate"
                            required>
                        <option v-for="(state, index) in predefinedGameStates" :key="index" :value="index">
                            {{state.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                  <button v-if="selectedPredefinedGameSate !== null"
                        class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 mt-5  text-white"
                        type="button"
                        @click="removePredefinedGameState">
                    Remove
                  </button>
                </form>

                <!-- SAVE/CLEAR-->
                <div class="flex gap-4 itens-center mt-5 text-white">
                  <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-green-600 hover:bg-green-900"
                        type="button"
                        @click="saveState">
                    <span v-if="selectedPredefinedGameSate !== null">Set State</span>
                    <span v-else>Save State</span>
                  </button>

                  <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-red-600 hover:bg-red-900"
                        type="button"
                        @click.prevent="clearState">
                    Clear State
                  </button>
                </div>
                  
                <!-- TEXTAREA/CODE -->
                <div class="w-full max-height-limiter flex justify-center divide-x-2 mt-5">
                  <textarea class="w-full overflow-auto resize-none rounded-lg border-2 border-blue-600 focus:outline-none focus:border-blue-900"
                    v-model="editableGameState"
                    @change="editableGameState = JSON.stringify(JSON.parse(editableGameState), null, 2)">
                  </textarea>

                  <code-highlight class="w-full overflow-auto max-height-limiter" language="json"> 
                    {{ JSON.parse(editableGameState) }} 
                  </code-highlight>
                </div>
                
              </div>


            </div>

            <!-- IF (addingPredefinedGameState = true) -->
            <div v-else class="w-full">
              
              <form @submit.prevent="addPredefinedGameState" class="w-ful flex flex-wrap">
                <!--  ........................................         3          ........................................................... -->
                <p class="text-xl font-semibold text-sitepurple">Adding Predefined State</p>

                <div class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left">
                    Way to Add
                  </label>
                  <div class="relative">
                    <select class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white"
                          v-model="addingPredefinedGameStateThroughFile"
                          required>
                      <option :value="true">Multiple statuses, through JSON file</option>
                      <option :value="false">Single status, through form fields</option>
                    </select>
                  </div>
                </div>

                <!-- addingPredefinedGameStateThroughFile == true -->
                <!-- ........................................................Multiple statuses, through JSON file -->
                <div v-if="addingPredefinedGameStateThroughFile" class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="stateFileInput">
                    Upload File
                  </label>
                  <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="stateFileInput" 
                        accept="application/json"
                        type="file"
                        @change="onFileChanged"/>
                  
                  <code-highlight class="w-full overflow-auto max-height-limiter" language="json"> 
                    {{ exampleFileFormat }} 
                  </code-highlight>
                    
                </div> 
                  
                <!-- addingPredefinedGameStateThroughFile == false -->
                <!-- ..........................................................Single status, through form fields -->
                <div v-else class="w-full px-3">
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="stateNameInput">
                    State Name
                  </label>
                  <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                        id="stateNameInput" 
                        type="text"
                        v-model="newPredefinedGameState.name" 
                        required>
              
                  <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="stateInput">
                    State
                  </label>

                  <!-- TEXTAREA -->
                  <textarea class="w-full overflow-auto max-height-limiter resize-none rounded-lg border-2 border-blue-600 focus:outline-none focus:border-blue-900"
                      id="stateInput" 
                      rows="10" 
                      required 
                      v-model="newPredefinedGameState.state"
                    @change="newPredefinedGameState.state = JSON.stringify(JSON.parse(newPredefinedGameState.state), null, 2)">
                  </textarea>
                    
                </div>

                <div>
                  <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg bg-green-600 hover:bg-green-900 text-white" 
                        type="submit">
                    Add State
                  </button>
                </div>

              </form>

            </div>


          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b  text-white" >
            
            <div v-if="addingPredefinedGameState == false" class="flex justify-center w-full">
              
              <div v-if="editingState == false">
                <!-- ........................................         1 footer          ........................................................... -->
                <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg bg-yellow-600 hover:bg-yellow-900"
                      @click="editState">
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M19.404,6.65l-5.998-5.996c-0.292-0.292-0.765-0.292-1.056,0l-2.22,2.22l-8.311,8.313l-0.003,0.001v0.003l-0.161,0.161c-0.114,0.112-0.187,0.258-0.21,0.417l-1.059,7.051c-0.035,0.233,0.044,0.47,0.21,0.639c0.143,0.14,0.333,0.219,0.528,0.219c0.038,0,0.073-0.003,0.111-0.009l7.054-1.055c0.158-0.025,0.306-0.098,0.417-0.211l8.478-8.476l2.22-2.22C19.695,7.414,19.695,6.941,19.404,6.65z M8.341,16.656l-0.989-0.99l7.258-7.258l0.989,0.99L8.341,16.656z M2.332,15.919l0.411-2.748l4.143,4.143l-2.748,0.41L2.332,15.919z M13.554,7.351L6.296,14.61l-0.849-0.848l7.259-7.258l0.423,0.424L13.554,7.351zM10.658,4.457l0.992,0.99l-7.259,7.258L3.4,11.715L10.658,4.457z M16.656,8.342l-1.517-1.517V6.823h-0.003l-0.951-0.951l-2.471-2.471l1.164-1.164l4.942,4.94L16.656,8.342z"
                      clip-rule="evenodd"/>
                  </svg> 
                  Edit State
                </button>
              </div>

              <!-- editingState == true -->
              <div v-else class="flex justify-between w-full  gap-4 items-center">
                <!--  ........................................         2 footer          ........................................................... -->
                <div>
                  <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                        type="button"
                        @click="cancelUpdateState">
                    <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path  d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
                    </svg>
                    Back
                  </button>
                </div>
                <div>
                  <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg bg-yellow-600 hover:bg-yellow-900"
                        type="button"
                        @click="addingPredefinedGameState = true">
                    New Predefined State
                  </button>
                </div>

              </div>

            </div>

            <!-- addingPredefinedGameState == true -->
            <div v-else class="flex justify-between w-full  gap-4 items-center">  
              <div>
                <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900"
                      type="button"
                      @click="resetPredefinedGameStateForm">
                  <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path  d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
                  </svg>
                  Back
                </button>
              </div>

              <!-- <div>
                <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg bg-green-600 hover:bg-green-900"
                      type="button">
                  Add State
                </button>
              </div> -->
              
            </div>
            
          
          </div>

        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
  // import axios from "axios"
  import { gameStateService } from "../../services"
  
  // import SshPre from 'simple-syntax-highlighter'
  // import 'simple-syntax-highlighter/dist/sshpre.css'
  
  import CodeHighlight from "vue-code-highlight/src/CodeHighlight.vue";
  import "vue-code-highlight/themes/duotone-sea.css";
  // import "vue-code-highlight/themes/window.css";

  import { CopyToClipboard } from '@xqsit94/vue3-copy-to-clipboard'

  export default {
    name: "gameStateModal",
  
    components: { CodeHighlight, CopyToClipboard},
  
    props: ['selectedCasino', 'gameName', 'sessionKey'],
  
    data() {
      return {
        gameState: '',
        editingState: false,
        editableGameState: '',
        addingPredefinedGameState: false,
        predefinedGameStates: [],
        addingPredefinedGameStateThroughFile: false,
        newPredefinedGameState: {
          name: '',
          state: ''
        },
        statusFileData: null,
        selectedPredefinedGameSate: null,
      }
    },
  
    watch: {
      addingPredefinedGameState(newVal, oldVal) {
        if (!newVal && oldVal) {
          this.updatePredefinedGameStatesList()
        }
      },
      selectedPredefinedGameSate(newVal) {
        this.editableGameState = JSON.stringify(this.predefinedGameStates[newVal].state, null, 2)
      }
    },
  
    computed: {
      exampleFileFormat() {
        const example = {
          "bonus1": {
            "statusId": 1,
            "symbols": [1, 2, 3],
            "win": 200,
            "...": '...'
          },
  
          "bonus2": {
            "statusId": 2,
            "symbols": [1, 2, 3],
            "win": 200,
            "...": '...'
          },
        }
  
        return JSON.stringify(example, null, 2)
      }
    },
  
    mounted() {
      if(this.selectedCasino.providerTypeId == 5){
        this.getInternationalState()
      }
      else{
        this.getState()
      }

      this.updatePredefinedGameStatesList()
    },
  
    methods: {
      async getState() {
        if(this.selectedCasino.providerTypeId == 5){
          this.getInternationalState()
        }
        else{
          this.gameState = ''
          
          let response = await gameStateService.getState(this.selectedCasino.url,this.gameName,this.sessionKey)
          console.log("-------------getState------------")
          console.log(this.selectedCasino.url)
          if (response.status === 200 && response.data) {
            console.log(response.data)
            this.gameState = response.data
            this.editableGameState = ''
          }
      }
      },
      async getInternationalState() {
        this.gameState = ''
  
        let response = await gameStateService.getInternationalState(this.selectedCasino.url, this.selectedCasino.accessToken,this.sessionKey)
        console.log("-------------getInternationalState international------------")
        console.log(this.selectedCasino.url)
        if (response.status === 200 && response.data) {
          console.log(response.data)
          this.gameState = response.data
          this.editableGameState = ''
        }
      },
      async clearState() {
        let response = await gameStateService.clearState(this.selectedCasino.url,this.gameName,this.sessionKey)
        if (response.status === 200) {
          this.gameState = ''
          this.editableGameState = ''
  
          this.closeModal()
        }
      },
      cancelUpdateState() {
        this.editingState = false
        this.editableGameState = ''
        this.selectedPredefinedGameSate = null
      },
      async saveState() {
        let response
        if(this.selectedCasino.providerTypeId !=5){
          console.log("-------------saveState------------")
          response = await gameStateService.saveState(this.selectedCasino.url, this.gameName, this.sessionKey,this.editableGameState)
        }
        else{
          console.log("-------------saveState international------------")
          console.log(this.selectedCasino.url)
          response = await gameStateService.saveInternationalState(this.selectedCasino.url,this.selectedCasino.accessToken, this.sessionKey,this.editableGameState)
        }

        if (response.status === 200) {
          this.gameState = this.editableGameState
          this.editingState = false
          this.editableGameState = ''
          this.closeModal()
        }
      },
      editState() {
        this.editableGameState = JSON.stringify(this.gameState, null, 2)
        this.editingState = true
      },
      closeModal() {
        this.$emit('close')
      },
      updatePredefinedGameStatesList() {
        if (this.gameName in localStorage) {
          let gameStates = JSON.parse(localStorage[this.gameName])
          if (Array.isArray(gameStates)) {
            this.predefinedGameStates = gameStates
          }
        }
      },
      addPredefinedGameState() {
        let gameStates = null
  
        if (!(this.gameName in localStorage)) {
          gameStates = []
        } else {
          gameStates = JSON.parse(localStorage[this.gameName])
  
          if (!Array.isArray(gameStates)) {
            gameStates = []
          }
        }
  
        if(this.addingPredefinedGameStateThroughFile) {
          Object.keys(this.statusFileData).forEach(statusName => {
            let index = gameStates.findIndex(status => status.name === statusName)
            if(index !== -1) {
              gameStates[index] = {
                name: statusName,
                state: this.statusFileData[statusName]
              }
            } else {
              gameStates.push({
                name: statusName,
                state: this.statusFileData[statusName]
              })
            }
          })
        } else {
          let index = gameStates.findIndex(status => status.name === this.newPredefinedGameState.name)
          if(index !== -1) {
            gameStates[index] = {
              name: this.newPredefinedGameState.name,
              state: JSON.parse(this.newPredefinedGameState.state)
            }
          } else {
            gameStates.push({
              name: this.newPredefinedGameState.name,
              state: JSON.parse(this.newPredefinedGameState.state)
            })
          }
        }
  
        localStorage[this.gameName] = JSON.stringify(gameStates)
        this.resetPredefinedGameStateForm()
      },
      resetPredefinedGameStateForm() {
        console.log("click back")
        this.newPredefinedGameState = {
          name: '',
          state: ''
        }
        this.addingPredefinedGameState = false
      },
      removePredefinedGameState() {
        let gameStates = JSON.parse(localStorage[this.gameName])
  
        gameStates.splice(this.selectedPredefinedGameSate, 1)
        localStorage[this.gameName] = JSON.stringify(gameStates)
  
        this.selectedPredefinedGameSate = null
        this.updatePredefinedGameStatesList()
        this.editableGameState = JSON.stringify(this.gameState, null, 2)
      },
      onFileChanged(event) {
        let uploadedFile = event.target.files[0]
  
        const reader = new FileReader()
        reader.readAsText(uploadedFile, 'UTF-8')
        reader.onload =  evt => {
          this.statusFileData = JSON.parse(evt.target.result)
        }
      }
    }
  }
  </script>
  
  <style scoped>
  .max-height-limiter {
    max-height: 52vmin
  }
  
  @media only screen and (max-width: 1280px) and (orientation: landscape) {
    .max-height-limiter {
      max-height: 40vmin
    }
  }
  </style>