import { handleResponse, requestOptions } from '../helpers'
import axios from "axios"

export const casinoService = {
  getAll,
  setCasino,
  deleteCasino,
  updateCasino,
  postGameUrl,
  internationalPostGameUrl,
  setTumbnail
}

//Admin Page
async function getAll() {
  console.log("--- Get all casinos. ----")
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/casinos`))
  return handleResponse(response)
}

// Admin Components - newCasinoModal, updateCasinoModal
async function setCasino(casino){
  console.log("--- Set casino. ----")
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinos/create`, {
    ...casino
  }))
  return handleResponse(response)
}

async function deleteCasino(id){
  console.log("--- Delete casino. ----")
  let response = await axios.request(requestOptions.delete(`${process.env.VUE_APP_SERVER_API}/casinos/${id}`))
  return handleResponse(response)
}

async function updateCasino(id, body){
  console.log("--- Update casino. ----")
  let response = await axios.request(requestOptions.put(`${process.env.VUE_APP_SERVER_API}/casinos/${id}`,{
    ...body
  }))
  return handleResponse(response)
}

//Game Launcher page
async function postGameUrl(casinoId, body){
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinos/${casinoId}/gameurl`,{
    ...body
}))
  return handleResponse(response)
}

async function internationalPostGameUrl(casinoId, body){
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinos/${casinoId}/internationalgameurl`,{
    ...body
}))
  console.log(response)
  return handleResponse(response)
}

async function setTumbnail(casinoId, gameSlug) {
  console.log("--- Set Tumbnail. ----")
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinos/${casinoId}/tumbnail`,{
    gameSlug
  }))
  return handleResponse(response)
}
