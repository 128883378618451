import { handleResponse, requestOptions } from '../helpers'
import axios from "axios"

export const casinoGamesService = {
  // getAll,
  getCasinoGames,
  setAllCasinoGames,
  setCasinoGamesByCasinoId,
  updateAllGames,
  checkGameUpdates,
  deleteCasinoGames
}


// async function getAll(){
//   console.log('--- Get all casino games. ----')
//   let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/casinoGames`))
//   return handleResponse(response)
// }

//Admin Page
async function getCasinoGames(casinoId) {
  let response = await axios.request(requestOptions.get(`${process.env.VUE_APP_SERVER_API}/casinoGames/casinoId/${casinoId}`))
  console.log('--- Get all games for casino. ----')
  return handleResponse(response)
}

async function setAllCasinoGames(){
  console.log("--- Set casinoGames. ----")
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinoGames/create`))
  return handleResponse(response)
}

async function setCasinoGamesByCasinoId(casinoId){
  console.log("--- Set casinoGames byCasino Id. ----")
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinoGames/create/casinoId/${casinoId}`))
  return handleResponse(response)
}

// async function postUpdateGames(casinoName, casinoId, games) {
//   console.log("--- Post update Games ---")
//   let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinoGames/updateGames`, {
//     casinoName,
//     casinoId,
//     games
//   }))
//   // console.log(response)
//   return handleResponse(response)
// }

async function updateAllGames(casinoId) {
  console.log("--- Put update Games ---")
  let response = await axios.request(requestOptions.put(`${process.env.VUE_APP_SERVER_API}/casinoGames/updateAllGames`))
  return handleResponse(response)
}

async function checkGameUpdates(game) {
  console.log("--- Check Game Updates ---")
  let response = await axios.request(requestOptions.put(`${process.env.VUE_APP_SERVER_API}/casinoGames/checkGameUpdates`,
    game
  ))
  return handleResponse(response)
}

async function deleteCasinoGames(casinoId, games){
  console.log("--- Delete Games. ---")
  console.log(games)
  let response = await axios.request(requestOptions.post(`${process.env.VUE_APP_SERVER_API}/casinoGames/deleteGames/casinoId/${casinoId}`,{
    ...games
  }))
  return handleResponse(response)
}

