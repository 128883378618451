<template>
  <div>
    <div class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
      <div class="relative w-auto my-6 mx-auto max-w-6xl">
        <!--content-->
        <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          <!--header-->
          <div class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 nrounded-t">
            <h3 class="text-3xl font-semibold text-sitepurple">Game Settings</h3>
            <button type="button" @click="closeModal">
              <svg class="w-8 h-8  text-red-500  hover:bg-red-500 hover:text-white font-bold text-sm rounded" 
                    fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                d="M15.898,4.045c-0.271-0.272-0.713-0.272-0.986,0l-4.71,4.711L5.493,4.045c-0.272-0.272-0.714-0.272-0.986,0s-0.272,0.714,0,0.986l4.709,4.711l-4.71,4.711c-0.272,0.271-0.272,0.713,0,0.986c0.136,0.136,0.314,0.203,0.492,0.203c0.179,0,0.357-0.067,0.493-0.203l4.711-4.711l4.71,4.711c0.137,0.136,0.314,0.203,0.494,0.203c0.178,0,0.355-0.067,0.492-0.203c0.273-0.273,0.273-0.715,0-0.986l-4.711-4.711l4.711-4.711C16.172,4.759,16.172,4.317,15.898,4.045z"
                clip-rule="evenodd"/>
              </svg>            
            </button>
          </div>
          <!--body-->
          <div class="relative p-6 flex-auto">
            <form class="w-full max-w-lg flex flex-wrap -mx-3 mb-6">

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-buyBonus">
                  Buy Bonus
                </label>
                <div class="relative">
                  <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name="grid-buyBonus"
                        id="grid-buyBonus"
                        v-model="selectedBuyBonus"
                        required>
                        <option :value="true">yes</option>
                        <option :value="false">no</option>
                  </select>
                </div>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-extraBet">
                  Extra Bet
                </label>
                <div class="relative">
                  <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name="grid-extraBet"
                        id="grid-extraBet"
                        v-model="selectedExtraBet"
                        required>
                        <option :value="true">yes</option>
                        <option :value="false">no</option>
                  </select>
                </div>
              </div>

              <div class="w-full px-3">
                <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 text-left" for="grid-spinSpeed">
                  Spin Speed
                </label>
                <div class="relative">
                  <select class=" block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        name="grid-spinSpeed"
                        id="grid-spinSpeed"
                        v-model="selectedSpinSpeed"
                        required>
                        <option :ngValue=0>0</option>
                        <option :ngValue=1000>1000</option>
                        <option :ngValue=2000>2000</option>
                        <option :ngValue=3000>3000</option>
                        <option :ngValue=4000>4000</option>
                        <option :ngValue=5000>5000</option>
                        <option :ngValue=6000>6000</option>
                        <option :ngValue=7000>7000</option>
                        <option :ngValue=8000>8000</option>
                        <option :ngValue=9000>9000</option>
                  </select>
                </div>
              </div>

            </form>
          </div>
          <!--footer-->
          <div class="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b" >
            <button class="px-6 py-2 font-semibold rounded-lg shadow-lg bg-blue-600 hover:bg-blue-900 text-white"
                  type="button"
                  @click="setGameConfiguration">
              Submit
            </button>
          </div>

        </div>
      </div>
    </div>
    <div class="opacity-25 fixed inset-0 z-40 bg-black"></div>
  
  </div>
</template>

<script>
import { cond } from "lodash"
import { userService } from "../../services"

export default {
  name: "game-settings",
  props: ['user', 'casino', 'game'],

  data() {
    return {
      selectedBuyBonus: '',
      selectedSpinSpeed: 0,
      selectedExtraBet: '',
    }
  },
  
  mounted() {
    this.getDefaultGameConfiguration(this.user, this.casino, this.game)
  },

  methods: {
    closeModal() {
      this.$emit('close')
    },
    async getDefaultGameConfiguration(user, casino, game){
      let gameConfiguration = await userService.getUserConfigurationForGame(user.id, casino.id, game.gameName)
      this.selectedBuyBonus = await gameConfiguration.allowBuyBonus
      this.selectedExtraBet = await gameConfiguration.allowExtraBet
      this.selectedSpinSpeed = await Number(gameConfiguration.minDurationMs)
      console.log("ggggggggggggggggggggggggggggggggggg")
      console.log(typeof(this.selectedSpinSpeed))
      console.log("getDefaultGameConfiguration on modal: --- BuyBonus:"+this.selectedBuyBonus+", extraBet:"+this.selectedExtraBet+", SpinSpeed:"+this.selectedSpinSpeed, +" typeOf: "+typeof(this.selectedSpinSpeed))
    },
    async setGameConfiguration(){

      let body = {
        allowBuyBonus: await this.selectedBuyBonus,
        allowExtraBet: await this.selectedExtraBet,
        minDurationMs: await Number(this.selectedSpinSpeed)
      }
      let gameConfigurationUpdated = await userService.setUserConfigurationForGame(this.user.id, this.casino.id, this.game.gameName, body)

      if(gameConfigurationUpdated != []){
        console.log("setGameConfiguration on modal: --- BUYBONUS:"+this.selectedBuyBonus+", extraBET:"+this.selectedExtraBet+", SPINSPEED:"+this.selectedSpinSpeed, +" typeOf: "+typeof(this.selectedSpinSpeed))
        return this.$toast.success(`Game Configurations Updated..!`)
      } else {
        console.log("Game configurations not updated")
        return this.$toast.error(`Game configurations not updated`)
      }
    },

  },
  watch: {
    // selectedExtraBet(newExtraBet , oldExtraBet) {
    //   if (newExtraBet !== oldExtraBet) {
    //     this.gameUrl = ''
    //     this.parameters = ''
    //     this.qrcode = ''
    //   }
    //   console.log("selectedExtraBet: "+this.selectedExtraBet)
    // },
    // selectedBuyBonus(newBuyBonus, oldBuyBonus) {
    //   if (newBuyBonus !== oldBuyBonus) {
    //     this.gameUrl = ''
    //     this.parameters = ''
    //     this.qrcode = ''
    //   }
    //   console.log("selectedBuyBonus: "+this.selectedBuyBonus)
    // },
    // selectedSpinSpeed(newSpinSpeed ,oldSpinSpeed) {
    //   if (newSpinSpeed !== oldSpinSpeed) {
    //     this.gameUrl = ''
    //     this.parameters = ''
    //     this.qrcode = ''
    //   }
    //   console.log("selectedSpinSpeed: "+this.selectedSpinSpeed)
    // },
   
  },
}
</script>

<style scoped>

</style>
