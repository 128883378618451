const configMixin = {
  data() {
    return {
      gamingServiceEndpoint: process.env.VUE_APP_GAMING_SERVICE_BASEURL,
      accessToken: process.env.VUE_APP_GAMING_SERVICE_ACCESS_TOKEN,
      // playerIds: [
      //   {
      //     key: 'quinel1',
      //     name: 'QUINEL1',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'quinel2',
      //     name: 'QUINEL2',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'bordalo',
      //     name: 'Bordalo',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'andrade',
      //     name: 'Andrade',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'rogerio',
      //     name: 'Rogério',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'rogerio2',
      //     name: 'Rogério - 2',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'anabela',
      //     name: 'Anabela',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'andrebaptista',
      //     name: 'André Baptista',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'jhonivieceli',
      //     name: 'Jhoni Vieceli',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'josesilva',
      //     name: 'José Silva',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'heitorsilva',
      //     name: 'Heitor Silva',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'mariocaldas',
      //     name: 'Mário Caldas',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'luispinto',
      //     name: 'Luís Pinto',
      //     loginUser: 'cristaltec',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'joaocepeda',
      //     name: 'Joao',
      //     loginUser: 'cristaltec2',
      //     type: 'dev'
      //   },
      //   {
      //     key: 'cristian',
      //     name: 'Cristian',
      //     type: 'tester',
      //     allowedGames: [
      //       'classic_blackout'
      //     ]
      //   },
      //   {
      //     key: 'iannone',
      //     name: 'Iannone',
      //     type: 'tester',
      //     allowedGames: [
      //       'classic_blackout'
      //     ]
      //   },
      //   {
      //     key: 'fanetti',
      //     name: 'Fanetti',
      //     type: 'tester',
      //     allowedGames: [
      //       'classic_blackout'
      //     ]
      //   },
      //   {
      //     key: 'fabio',
      //     name: 'Fabio',
      //     type: 'tester',
      //     allowedGames: [
      //       'classic_blackout'
      //     ]
      //   },
      //   {
      //     key: 'alessio',
      //     name: 'Alessio',
      //     type: 'tester',
      //     allowedGames: [
      //       'classic_blackout'
      //     ]
      //   },
      //   {
      //     key: 'teodor',
      //     name: 'Teodor',
      //     type: 'tester',
      //     allowedGames: [
      //       'classic_blackout'
      //     ]
      //   }

      // ],
      gameModes: [
        {
          key: 'live',
          name: 'Live'
        },
        {
          key: 'fun',
          name: 'Fun'
        }
      ],
      gameQualities: [
        {
          name: 'Both',
          value: 0
        },
        {
          name: 'High',
          value: 1
        },
        {
          name: 'Low',
          value: 2
        }
      ],
      languages: [
        {
          key:'en',
          name: 'en'
        },
        {
          key:'it',
          name: 'it'
        },
        {
          key:'pt',
          name: 'pt'
        },
        {
          key:'es',
          name: 'es'
        },
        {
          key:'ru',
          name: 'ru'
        },
        {
          key:'fr',
          name: 'fr'
        },
        {
          key:'bg',
          name: 'bg'
        },
        {
          key:'zh',
          name: 'zh'
        },
        {
          key:'sr',
          name: 'sr'
        },
        {
          key:'ro',
          name: 'ro'
        },
        {
          key:'ar',
          name: 'ar'
        },
        {
          key:'de',
          name: 'de'
        },
      ],
      buyBonuses: [
        {
          key:true,
          name: 'yes'
        },
        {
          key:false,
          name: 'no'
        },
      ],
      spinSpeeds: [
        {
          key:0
        },
        {
          key:1
        },
        {
          key:2
        },
        {
          key:3
        },
        {
          key:4
        },
        {
          key:5
        },
        {
          key:6
        },
        {
          key:7
        },
        {
          key:8
        },
        {
          key:9
        },
        {
          key:10
        },
      ],
      extraBets: [
        {
          key:true,
          name: 'yes'
        },
        {
          key:false,
          name: 'no'
        },
      ],
      currencies: [
        {
          key:'BNS',
          name: 'BNS'
        },
        {
          key:'CRD',
          name: 'CRD'
        },
        {
          key:'FUN',
          name: 'FUN'
        },
        {
          key:'EUR',
          name: 'EUR'
        },
        {
          key:'USD',
          name: 'USD'
        }
      ],
    }
  },
  methods: {
    checkUser(userToCheck){
      return this.playerIds[this.playerIds.findIndex(playerId => playerId.name === userToCheck)]
    },
    filterUserGames(user) {
      const userObject = this.playerIds.find(player => player.name === user)
      return userObject.allowedGames ? userObject.allowedGames : '*'
    },
    checkUserType(user){
      const userObject = this.playerIds.find(player => player.name === user)
      return userObject.type ? userObject.type : 'dev'
    }
  }
}

export default configMixin