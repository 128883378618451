<template>
  <div>
    <div class="container mx-auto md:px-8">
      <game-settings-modal 
            :user=this.currentUser
            :casino=this.selectedCasino
            :game=this.selectedGame
            v-if="showGameSettingsModal" @close="closeGameSettingsModal"> 
      </game-settings-modal>

      <settings-modal 
            @local-server-updated="updateLocalServerUrl"
            @show-qr-code-updated="updateQrCodeShow"
            @refresh-session-tokens-updated="updateRefreshSessionTokens"
            v-if="showSettingsModal" @close="closeSettingsModal">
      </settings-modal>

      <game-state-modal  
            :sessionKey=this.sessionId 
            :selectedCasino=this.selectedCasino
            :gameName=this.selectedGame.gameName
            v-if="showGameStateModal" @close="closeGameStateModal" >
      </game-state-modal>


      <form class="flex flex-col items-center gap-y-5">
        <h1 class="text-3xl uppercase font-semibold mb-10 tracking-wider text-white ">Game Launcher</h1>
        
        <!-- PLAYER -->
        <div class="form-field">
          <label class="w-1/4 md:w-1/3" id="player" for="playerInput">
            Player
          </label>
          <input class="flex-1" name="player" id="playerInput" 
                v-model="currentUser.name" required disabled/>
        </div>

        <!-- CASINO -->
        <div class="form-field">
          <label class="w-1/4 md:w-1/3" id="playerCasino" for="casinoInput">
            Casino
          </label>
          <select class="flex-1" name="playerCasino" id="casinoInput" 
                v-model="selectedCasino" required >
                <option value="" disabled>Pick Casino</option>
            <option v-for="playerCasino in playerCasinos" :key="playerCasino.name" :value="playerCasino"  >
              {{ playerCasino.name }}
            </option>
          </select>
          </div> 

          <!-- GAME / GAMEVERSION -->
          <div class="w-full flex flex-col md:flex-row items-center justify-between gap-5">
          <div class="form-field">
            <label class="w-1/4 md:w-1/3" id="playerCasinoGames" for="gameInput">
              Game
            </label>
            <select  class="flex-1" name="playerCasinoGames" id="gameInput" 
                  v-model="selectedGame" required>
              <!-- <option value="" disabled>Pick Game</option> -->
              <option v-for="userCasinoGame in playerCasinoGames" :key="userCasinoGame.gameName" :value="userCasinoGame">
                {{ userCasinoGame.gameName }}
              </option>
            </select>
          </div> 

          <div class="form-field"
            v-if="selectedCasino.providerTypeId=='5' && selectedGame!=''" >
            <label class="w-1/4 md:w-1/3" id="gameVersions" for="gameVersionInput">
              Game Version
            </label>
            <select class="flex-1" name="gameVersions" id="gameVersionInput" 
                    v-model="selectedGameVersion" required>
              <option v-for="gameVersion in gameVersions" :key="gameVersion.code" :value="gameVersion">
                {{ gameVersion.code }} Rtp: {{gameVersion.rtp}} 
              </option>
            </select>
          </div>
        </div>

        <!-- GAME SETTINGS -->
        <div class="p-4 flex flex-col gap-4 items-center"
          v-if="selectedCasino.providerTypeId=='5' && selectedGame!=''">
            <button type="button" class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-blue-600 hover:bg-blue-900"
                @click="openGameSettingsModal">
                <svg class="rotate w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd"
                        d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                        clip-rule="evenodd"/>
                </svg>  
                Game Settings
            </button>
          </div>
        
        <!-- TUMBNAIL/CHECK GAME UPDATES -->
        <div class="form-field md:w-1/4"
          v-if="selectedCasino!='' && selectedGame!='' && selectedCasino.providerTypeId!='5'|| 
          (selectedCasino!='' && selectedGame!='' && selectedCasino.providerTypeId=='5' && selectedGameVersion!='')">
          <img  v-if="tumbnail" class="h-24 w-24 md:w-auto" :src="tumbnail" alt=""/>
          <button type="button" class="button-with-icon w-full" id="checkGameUpdatesButton" 
                @click="checkGameUpdates">
            <svg class="rotate w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                    clip-rule="evenodd"/>
            </svg>
            <span >
              Check Game Updates
            </span>
          </button>
        </div> 

        <!-- EXTRA BET / BUY BONUS / SPIN SPEED  -->
        <!-- <div class="w-full flex flex-col md:flex-row items-center justify-between gap-5"
          v-if="selectedCasino.providerTypeId=='5' && selectedGame!=''">
          
          <div class="form-field" 
            v-if="selectedCasino.providerTypeId=='5' && selectedGame!=''">
            <label class="w-1/4 md:w-1/3" id="extraBet" for="extraBetInput">
              Extra Bet
            </label>
            <select class="flex-1" name="extraBet" id="extraBetInput" 
                  v-model="selectedExtraBet" required >
              <option v-for="extraBet in extraBets" :key="extraBet.key" :value="extraBet.key"  >
                {{ extraBet.name }}
              </option>
            </select>
          </div>

          <div class="form-field">
            <label class="w-1/4 md:w-1/3" id="buyBonus" for="buyBonusInput">
              Buy Bonus
            </label>
            <select class="flex-1" name="buyBonus" id="buyBonusInput" 
                  v-model="selectedBuyBonus" required>
              <option v-for="buyBonus in buyBonuses" :key="buyBonus.key" :value="buyBonus.key">
                {{ buyBonus.name }}
              </option>
            </select>
          </div>

          <div class="form-field">
            <label class="w-1/4 md:w-1/3" id="spinSpeed" for="spinSpeedInput">
              Spin Speed
            </label>
            <select class="flex-1" name="spinSpeed" id="spinSpeedInput"
                    v-model="selectedSpinSpeed" required>
              <option v-for="spinSpeed in spinSpeeds" :key="spinSpeed.key" :value="spinSpeed.key">
                {{ spinSpeed.key}}
              </option>
            </select>
          </div>

        </div> -->

        <!-- RELEASE DATE -->
        <div v-if="selectedCasino!='' && selectedGame!='' && selectedCasino.providerTypeId!='5'|| 
          (selectedCasino!='' && selectedGame!='' && selectedCasino.providerTypeId=='5' && selectedGameVersion!='')">
          <p class="flex-1 text-xs text-white" name="releaseDate" id="releaseDateInput">
            Release Date: {{selectedGame.releaseDate}} 
          </p>
        </div>

        <!-- LANGUAGE/CURRENCY  -->
        <div class="w-full flex flex-col md:flex-row items-center justify-between gap-5">
          <div class="form-field">
            <label class="w-1/4 md:w-1/3" id="language" for="languageInput">
              Language
            </label>
            <select class="flex-1" name="language" id="languageInput" 
                  v-model="selectedLanguage" required>
              <option v-for="language in languages" :key="language.key" :value="language.key">
                {{ language.name }}
              </option>
            </select>
          </div>

          <div class="form-field">
            <label class="w-1/4 md:w-1/3" id="currency" for="currencyInput">
              Currency
            </label>
            <select class="flex-1" name="currency" id="currencyInput"
                    v-model="selectedCurrency" required>
              <option v-for="currency in currencies" :key="currency.key" :value="currency.key">
                {{ currency.name }}
              </option>
            </select>
          </div>

        </div>

        <!-- MODE/QUALITY -->
        <div class="w-full flex flex-col md:flex-row items-center justify-between gap-5">
          <div class="form-field">
            <label class="w-1/4 md:w-1/3" id="mode" for="modeInput">
              Mode
            </label>
            <select class="flex-1" name="mode" id="modeInput" 
                  v-model="selectedMode" required>
              <option v-for="mode in gameModes" :key="mode.key" :value="mode.key">
                {{ mode.name }}
              </option>
            </select>
          </div>

          <div class="form-field">
            <label class="w-1/4 md:w-1/3" id="quality" for="qualityInput">
              Quality
            </label>
            <select class="flex-1" name="quality" id="qualityInput"
                    v-model="selectedQualityValue" required>
              <option v-for="quality in gameQualities" :key="quality.value" :value="quality.value">
                {{ quality.name }}
              </option>
            </select>
          </div>

        </div>
      
        <!-- IF (MODE = live), 
          BALANCE, SESSIONID, 
          IF (MODE = live && selectedGame.providertypeId != 5 )
          AAMSSESSIONID, AAMSTICKETID 
         -->
        <div class="w-full flex flex-col gap-y-5" v-if="selectedCasino!='' && selectedMode==='live' ">
          <div class="form-field">
            <label class="w-1/4 text-xs sm:text-sm md:w-1/3" id="balance" for="balanceInput">
              Balance 
            </label>
            <input class="flex-1 w-1/3 px-1.5 sm:w-1/2 sm:px-2" id="balanceInput"
                  type="number" min="0.25" max="1000.00" step="0.25"
                  v-model="selectedSessionAmount" 
                  required>
            <button type="button" @click="selectedSessionAmount = '20.00'">20</button>
            <button type="button" @click="selectedSessionAmount = '50.00'">50</button>
            <button type="button" @click="selectedSessionAmount = '100.00'">100</button>
          </div>

          <div class="form-field" 
            v-if="!alwaysRefreshSessionTokens">
            <label class="w-1/4 md:w-1/3" id="sessionId" for="sessionIdInput">
              Session Id
            </label>
            <input type="text" class="flex-1 w-1/2" id="sessionIdInput" 
                  v-model="sessionId" 
                  required 
                  :disabled="requiredFieldsMissing">
            <button type="button" class="button-with-icon" id="getSessionIdButton" 
                  @click.prevent="getSessionId" 
                  :disabled="requiredFieldsMissing">
              <svg class="rotate w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clip-rule="evenodd"/>
              </svg>
              <span class="hidden md:block">Generate</span>
            </button>
          </div>

          <div class="form-field" 
            v-if="selectedCasino.providerTypeId!='5' && !alwaysRefreshSessionTokens">
            <label class="w-1/4 md:w-1/3" id="aamsSessionId" for="aamsSessionIdInput">
              AAMS Session Id
            </label>
            <input type="text" class="flex-1 w-1/2" id="aamsSessionIdInput" 
                  v-model="aamsSessionId" 
                  required :disabled="requiredFieldsMissing">
            <button type="button" class="button-with-icon" id="ammsSessionIdButton" 
                  @click.prevent="getAmmsSessionId" 
                  :disabled="requiredFieldsMissing">
              <svg class="rotate w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clip-rule="evenodd"/>
              </svg>
              <span class="hidden md:block">Generate</span>
            </button>
          </div>

          <div class="form-field" 
            v-if="selectedCasino.providerTypeId!='5' && !alwaysRefreshSessionTokens">
            <label class="w-1/4 md:w-1/3" id="aamsTicketId" for="aamsTicketIdInput">
              AAMS Ticket Id
            </label>
            <input type="text" class="flex-1 w-1/2" id="aamsTicketIdInput" 
                  v-model="aamsTicketId" 
                  required :disabled="requiredFieldsMissing">
            <button type="button" class="button-with-icon" id="aamsTicketIdButton" 
                  @click.prevent="getAmmsTicketId" 
                  :disabled="requiredFieldsMissing">
              <svg class="rotate w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                      clip-rule="evenodd"/>
              </svg>
              <span class="hidden md:block">Generate</span>
            </button>
          </div>

        </div>

        <!-- GAME URL -->
        <div class="form-field"
          v-if="(selectedCasino!='' && selectedCasino.providerTypeId!='5')|| (selectedCasino!='' && selectedCasino.providerTypeId=='5' && selectedGameVersion!='')">
          <label class="w-1/4 md:w-1/3" id="fetchUrl" for="fetchUrlInput">
            Game URL
          </label>
          <input type="text" class="flex-1 w-1/2" id="fetchUrlInput" 
                v-model="gameUrl" readonly>
          <button type="button" class="button-with-icon" @click="copyURL" v-if="gameUrl" 
                :disabled="requiredFieldsMissing">
            <svg class="flip w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"/>
              <path
                  d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"/>
            </svg>
            <span class="hidden md:block">Copy</span>
          </button>

          <button type="button" class="button-with-icon" id="fetchUrlButton" 
                @click.prevent="getGameUrl" 
                :disabled="requiredFieldsMissing">
                <!-- :disabled="requiredFieldsMissingForGameUrl"> -->
            <svg class="flip w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd"
                    d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z"
                    clip-rule="evenodd"/>
            </svg>
            <span class="hidden md:block">Fetch URL</span>
          </button>

        </div>

        <!-- LOCAL SERVER -->
        <div class="form-field" 
          v-if="localServerGameUrl">
          <label class="w-1/4 md:w-1/3" id="localServerUrl" for="localServerUrlInput">Local Server URL</label>
          <input type="text" class="flex-1" id="localServerUrlInput"
                  v-model="localServerGameUrl.url" readonly>
          <button type="button" class="button-with-icon" 
                @click="copyLocalUrl" 
                :disabled="requiredFieldsMissing">
                <!-- :disabled="requiredFieldsMissingForGameUrl"> -->
            <svg class="flip w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"/>
              <path
                  d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"/>
            </svg>
            <span class="hidden md:block">Copy URL</span>
          </button>

        </div> 

        <!-- GAME URL PARAMETERS -->
        <div class="form-field"  
          v-if="parameters !== ''">
          <label class="w-1/4 text-xs md:w-1/3 md:text-sm" id="gameUrlParameters" for="gameUrlParametersInput">Game URL Params</label>
          <input type="text" class="flex-1 w-1/2" id="gameUrlParametersInput" 
                v-model="parameters" readonly>
          <button type="button" class="button-with-icon" @click="copyParameters" v-if="parameters"
                :disabled="requiredFieldsMissing">
            <svg class="flip w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path d="M8 2a1 1 0 000 2h2a1 1 0 100-2H8z"/>
              <path
                  d="M3 5a2 2 0 012-2 3 3 0 003 3h2a3 3 0 003-3 2 2 0 012 2v6h-4.586l1.293-1.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L10.414 13H15v3a2 2 0 01-2 2H5a2 2 0 01-2-2V5zM15 11h2a1 1 0 110 2h-2v-2z"/>
            </svg>
            <span class="hidden md:block">Copy</span>
          </button>
          
        </div>


        <!-- ERROR -->
        <div class="my-4 px-12 py-4 bg-red-200 text-red-800 border-2 rounded-lg border-red-800" 
              v-if="error">
          {{ error.message }}
        </div>

      </form>

      <!-- LAUNCH GAME (Local || Server) && QRCode-->
      <div class="mt-4 flex flex-wrap items-center justify-around gap-4" v-if="(serverGameUrl || localServerGameUrl) && !error">
        <h1 class="w-full text-center text-2xl uppercase font-semibold tracking-wider text-white ">Launch Game</h1>

        <div class="p-4 flex flex-col gap-4 items-center" v-if="localServerGameUrl">
          <a :href="localServerGameUrl.url" target="_blank"
              class="px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-orange-600 hover:bg-orange-900 transition-all duration-150">
              Local Machine
          </a>
          <img class="w-56 h-56" :src="localServerGameUrl.qrcode" alt="" v-if="showQrCode"/>
        </div>

        <div class="p-4 flex flex-col gap-4 items-center" v-if="serverGameUrl">
          <a :href="serverGameUrl.url" target="_blank"
              class="px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-blue-600 hover:bg-blue-900 transition-all duration-150">
              Server
          </a>
          <img class="w-56 h-56" :src="serverGameUrl.qrcode" alt="" v-if="showQrCode"/>
        </div>

      </div>

      <!-- SETTINGS /GAME STATE CONSOLE -->
      <div class="p-4 flex flex-col gap-4 items-center">
        <!-- inline-flex items-center gap-10 mt-16 -->
        <!-- <button type="button"
              class="inline-flex items-center gap-3 text-lg text-blue-500 px-4 transition-all duration-150 hover:text-blue-800 dark:hover:text-white hover:underline focus:outline-none"
              @click="showSettings = true">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                  clip-rule="evenodd"/>
          </svg>
          <span>
            Settings
          </span>
        </button> 
        <button type="button"
              v-if="selectedGame !== '' && sessionId !== '' && !error"
              class="inline-flex items-center gap-3 text-lg text-blue-500 px-4 transition-all duration-150 hover:text-blue-800 dark:hover:text-white hover:underline focus:outline-none"
              @click="showGameStateModal = true">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                  clip-rule="evenodd"/>
          </svg>
          <span>Game State Console</span>
        </button>
        -->
        <button class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-blue-600 hover:bg-blue-900"
              @click="openSettingsModal">
              <svg class="rotate w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd"
                      d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
                      clip-rule="evenodd"/>
              </svg>  
              Settings
        </button>
        <button v-if="this.selectedGame  && this.sessionId && !error"
              class="inline-flex px-6 py-2 font-semibold rounded-lg shadow-lg text-white bg-blue-600 hover:bg-blue-900"
              @click="openGameStateModal">
          <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd"
                  d="M2 5a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H4a2 2 0 01-2-2V5zm3.293 1.293a1 1 0 011.414 0l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414-1.414L7.586 10 5.293 7.707a1 1 0 010-1.414zM11 12a1 1 0 100 2h3a1 1 0 100-2h-3z"
                  clip-rule="evenodd"/>
          </svg>
          Game State Console
        </button>

      </div>
             
    </div>

  </div>
</template>

<script>
  import uuid from 'uuid'
  import qr from 'qr-image'
  import gameSettingsModal from "../components/gameLauncher/gameSettingsModal"
  import gameStateModal from "../components/gameLauncher/gameStateModal"
  import settingsModal from "../components/gameLauncher/settingsModal"
  import configMixin from "../mixins/configMixin"
  import {userCasinoGamesService, casinoService, casinoGamesService, userService} from "../services"
  import {mapGetters} from 'vuex'

  export default {
    name: 'Launcher',
    components: {settingsModal, gameStateModal, gameSettingsModal},
    mixins: [configMixin],
    props: { 
      mobile: Boolean,
      currentUser: Object
    },

    data() {
      return {
        selectedCasino: '',
        playerCasinos: [],

        selectedGame: '',
        playerCasinoGames: [],

        selectedGameVersion:'',
        gameVersions:[],
        // selectedExtraBet:'',
        // selectedBuyBonus:'',
        // selectedSpinSpeed:'',

        selectedLanguage: 'it',
        selectedCurrency: 'EUR',

        tumbnail: null,
        gameUpdated:'',

        selectedMode: 'live',
        selectedQualityValue: '',

        selectedSessionAmount: "20.00",
        
        error: '',
        sessionId: '',
        aamsSessionId: '',
        aamsTicketId: '',

        gameUrl: '',
        parameters: '',
        
        localServerUrl: '',
        showQrCode: true,
        
        showGameSettingsModal: false,
        showSettingsModal: false,
        showGameStateModal: false,

        alwaysRefreshSessionTokens: false,
      }
    },

    watch: {
      selectedCasino(){
        if(this.selectedCasino.id){
          console.log("selectedCasino: "+this.selectedCasino.name)
          this.getUserCasinoGames()
        }
        // console.log("Init ou Quando troco de casino")
        this.sessionId = ''
        this.aamsSessionId = ''
        this.aamsTicketId = ''
        this.gameUrl = ''
        this.parameters = ''
      },
      selectedGame(){
        if(this.selectedGame.id){
          console.log("selectedGame: "+this.selectedGame.gameName)

          if(this.selectedCasino.providerTypeId==5){
            this.gameVersions = this.selectedGame.gameVersions
            this.selectedGameVersion = this.selectedGame.gameVersions[0]
          }
          this.setTumbnail()
        }
        // console.log("Init ou Quando troco de jogo")
        this.sessionId = ''
        this.aamsSessionId = ''
        this.aamsTicketId = ''
        this.gameUrl = ''
        this.parameters = ''
      },
      selectedGameVersion(){
        // this.selectedGameVersion = this.selectedGame.gameVersions[0]
        console.log("selectedGameVersion: "+this.selectedGameVersion.code)

        // console.log("Init ou Quando troco de versao (ainda nao existe jogo com 2 versoes)")
        this.sessionId = ''
        this.gameUrl = ''
        this.parameters = ''
      },
      // selectedExtraBet(newExtraBet , oldExtraBet) {
      //   if (newExtraBet !== oldExtraBet) {
      //     this.gameUrl = ''
      //     this.parameters = ''
      //     this.qrcode = ''
      //   }
      //   this.setGameConfiguration()
      //   console.log("selectedExtraBet: "+this.selectedExtraBet)
      // },
      // selectedBuyBonus(newBuyBonus, oldBuyBonus) {
      //   if (newBuyBonus !== oldBuyBonus) {
      //     this.gameUrl = ''
      //     this.parameters = ''
      //     this.qrcode = ''
      //   }
      //   this.setGameConfiguration()
      //   console.log("selectedBuyBonus: "+this.selectedBuyBonus)
      // },
      // selectedSpinSpeed(newSpinSpeed ,oldSpinSpeed) {
      //   if (newSpinSpeed !== oldSpinSpeed) {
      //     this.gameUrl = ''
      //     this.parameters = ''
      //     this.qrcode = ''
      //   }
      //   this.setGameConfiguration()
      //   console.log("selectedSpinSpeed: "+this.selectedSpinSpeed)
      // },

      selectedLanguage(newLanguage, oldLanguage) {
        if (newLanguage !== oldLanguage) {
          this.gameUrl = ''
          this.parameters = ''
          this.qrcode = ''
        }
        console.log("selectedLanguage: "+this.selectedLanguage)
      },
      selectedCurrency(newCurrency, oldCurrency) {
        if (newCurrency !== oldCurrency) {
          this.gameUrl = ''
          this.parameters = ''
          this.qrcode = ''
        }
        console.log("selectedCurrency: "+this.selectedCurrency)
      },
      selectedMode(newMode, oldMode) {
        if (newMode !== oldMode) {
          this.gameUrl = ''
          this.parameters = ''
          this.qrcode = ''
        }
        console.log("selectedMode: "+this.selectedMode)
      },
      selectedQualityValue(newQualityValue, oldQualityValue) {
        if (newQualityValue !== oldQualityValue) {
          this.gameUrl = ''
          this.parameters = ''
          this.qrcode = ''
        }
        console.log("selectedQualityValue: "+this.selectedQualityValue)
      },
      // defaultSelectedQuality(){
      //   if(this.mobile == false){
      //     console.log("defaultSelectedQuality if mobile: "+ this.mobile)
      //     this.selectedQualityValue ='0'
      //   } else if(this.mobile == true){
      //     console.log("defaultSelectedQuality if mobile:"+ this.mobile)
      //     this.selectedQualityValue='2'
      //   }
      //   console.log(this.selectedQualityValue)
      // },
      selectedSessionAmount(newAmount) {
        if (newAmount !== Math.floor(newAmount)) {
          this.selectedSessionAmount = parseFloat(newAmount).toFixed(2)
          console.log("selectedSessionAmount: "+this.selectedSessionAmount)
        }
      },

    },

    computed: {
      sessionAmountInCredits() {
        console.log("sessionAmountInCredits: "+this.selectedSessionAmount)
        return this.selectedSessionAmount * 100
      },
      serverGameUrl() {
        if (this.gameUrl !== '') {
          return {
            url: this.gameUrl,
            qrcode: this.generateQrCode(this.gameUrl)
          }
        }
        return null
      },
      localServerGameUrl() {
        if (this.localServerUrl !== '' && this.parameters !== '') {
          const url = this.localServerUrl + '?' + this.parameters
          return {
            url: url,
            qrcode: this.generateQrCode(url)
          }
        }
        return null
      },
      requiredFieldsMissing() {
        return this.selectedCasino === '' || this.selectedGame === ''
      },
      requiredFieldsMissingForGameUrl(){
        if(this.selectedMode === 'fun'){
          return false
        }else{
          return this.sessionId ==='' || this.aamsSessionId === '' || this.aamsTicketId ===''
        }
      },
    },

    async mounted() {
      // this.fixViewportDimensions()
      // window.addEventListener('resize', this.fixViewportDimensions)
      // window.addEventListener('resize', )

      let user = this.currentUser
      if (user && !user.id) {
        let getUserId = await userService.getByEmail(user.email)
        user.id = getUserId.id
      }

      this.getUserCasinos()

      if(this.mobile == false){
        this.selectedQualityValue ='0'
      } else if(this.mobile == true){
        this.selectedQualityValue='2'
      }
      console.log("mobile:"+this.mobile +", selectedQualityValue:"+this.selectedQualityValue)

      // if('selectedGame' in localStorage) {
      //   this.selectedGame = localStorage.selectedGame
      // }
      // if('showQrCode' in localStorage) {
      //   this.showQrCode = JSON.parse(localStorage.showQrCode)
      // }
      // if('alwaysRefreshSessionTokens' in localStorage) {
      //   this.alwaysRefreshSessionTokens = JSON.parse(localStorage.alwaysRefreshSessionTokens)
      // }
      // if('localServer' in localStorage) {
      //   this.localServerUrl = localStorage.localServer
      // }
      
    },

    methods: {
      ...mapGetters([
        'getUser'
      ]),
      //OPEN AND CLOSE MODALS
      openGameSettingsModal() {
        this.showGameSettingsModal = true
      },
      closeGameSettingsModal() {
        this.showGameSettingsModal = false
        this.getDefaultGameConfiguration()
      },
      openSettingsModal() {
        this.showSettingsModal = true
      },
      closeSettingsModal() {
        this.showSettingsModal = false
      },
      openGameStateModal(){
        this.showGameStateModal = true
      },
      closeGameStateModal(){
        this.showGameStateModal = false
      },

      async getUserCasinos() {
        this.playerCasinos = await userCasinoGamesService.getUserCasinos(this.currentUser.id)
        if(this.playerCasinos.length > 0){
          this.setDefaultCasino()
        }
      },
      async setDefaultCasino() {
        this.selectedCasino = this.playerCasinos[0]
        console.log("setDefaultCasino: "+ this.selectedCasino.name)
      },
      async getUserCasinoGames(oldSelectedGame){
        let notOrderedUserCasinoGames = await userCasinoGamesService.getUserCasinoGames(this.currentUser.id, this.selectedCasino.id) 
        this.playerCasinoGames = _.orderBy(notOrderedUserCasinoGames, 'name', 'asc')
        // console.log("playerCasinoGames: ")
        // console.log(this.playerCasinoGames)
        if(oldSelectedGame){
          // console.log(oldSelectedGame.gameName)
          // const found = this.playerCasinoGames.find((gameName) => gameName = oldSelectedGame.gameName)
          // console.log(found)
          // this.selectedGame.gameName=this.playerCasinoGames[oldSelectedGame.gameName]
          console.log("oldSelectedGameName: "+this.selectedGame.gameName)
        }
        else if(this.playerCasinoGames.length > 0){
          this.setDefaultGame()
        }
      },
      async setDefaultGame(){
        this.selectedGame = this.playerCasinoGames[0]
        console.log("setDefaultGame: "+this.selectedGame.gameName)

        if(this.selectedCasino.providerTypeId == 5){
          this.setDefaultGameVersion()
          this.getDefaultGameConfiguration()
        }
      },
      async setDefaultGameVersion(){
        this.selectedGameVersion = this.selectedGame.gameVersions[0]
        console.log("setDefaultGameVersion: "+this.selectedGameVersion.code)
      },
      async getDefaultGameConfiguration(){
        let gameConfiguration = await userService.getUserConfigurationForGame(this.currentUser.id, this.selectedCasino.id, this.selectedGame.gameName)
        this.selectedBuyBonus = await gameConfiguration.allowBuyBonus
        this.selectedExtraBet = await gameConfiguration.allowExtraBet
        this.selectedSpinSpeed = await gameConfiguration.minDurationMs
        console.log("getDefaultGameConfiguration: --- BuyBonus: "+this.selectedBuyBonus+", extraBet: "+this.selectedExtraBet+", SpinSpeed: "+this.selectedSpinSpeed)
      },
      // async setGameConfiguration(){
      //   let body = {
      //     allowBuyBonus: await this.selectedBuyBonus,
      //     allowExtraBet: await this.selectedExtraBet,
      //     minDurationMs: await this.selectedSpinSpeed
      //   }
      //   // console.log("setGameConfiguration body ---")
      //   // console.log(body)
      //   let gameConfigurationUpdated = await userService.setUserConfigurationForGame(this.currentUser.id, this.selectedCasino.id, this.selectedGame.gameName, body)

      //   if(gameConfigurationUpdated != []){
      //     console.log("setGameConfiguration: --- BUYBONUS:"+this.selectedBuyBonus+", extraBET:"+this.selectedExtraBet+", SPINSPEED:"+this.selectedSpinSpeed)
      //   } else {
      //     console.log("Game configurations not updated")
      //   }
      // },
      async setTumbnail() {
        this.tumbnail = await casinoService.setTumbnail(this.selectedCasino.id, this.selectedGame.gameSlug)
      },
      clearError() {
        this.error = ''
      },
      async checkGameUpdates(){
        // console.log("oldSelectedGame: " +JSON.stringify(this.selectedGame))
        this.gameUpdated = await casinoGamesService.checkGameUpdates(this.selectedGame) 
        console.log(" --- Has the game been updated? " +this.gameUpdated)
        
        if(this.gameUpdated == 'true'){
          var oldSelectedGame = this.selectedGame
          console.log("oldSelectedGameId: " +JSON.stringify(this.selectedGame))

          let casinoGames =await casinoGamesService.setCasinoGamesByCasinoId(this.selectedCasino.id)
          console.log(casinoGames)


          await this.getUserCasinoGames(oldSelectedGame)
          // this.selectedGame.id = Number(oldSelectedGameId)
          // this.selectedGame()

          await this.$toast.success(`Game updated sucessfully!`)
          console.log("newSelectedGame: "+JSON.stringify(this.selectedGame))

        }else{
          // this.$toast.info(`The game has no updates available!`)
          this.$toast.info(`The game is updated!`)
        }
      },
      getSessionId() {
        this.clearError()
        this.sessionId = ''
        this.sessionId = uuid.v4()
      },
      getAmmsSessionId() {
        this.clearError()
        this.aamsSessionId = ''
        this.aamsSessionId = uuid.v4()
      },
      getAmmsTicketId() {
        this.clearError()
        this.aamsTicketId = ''
        this.aamsTicketId = uuid.v4()
      },
      // getGameUrl() {
      //   this.clearError()
      //   this.gameUrl = ''
      //   this.parameters = ''
      //   let body
      //   if (this.selectedMode === 'live') {
      //     if (!this.aamsTicketId || this.alwaysRefreshSessionTokens) {
      //       this.getAmmsTicketId()
      //     }
      //     if (!this.aamsSessionId || this.alwaysRefreshSessionTokens) {
      //       this.getAmmsSessionId()
      //     }
      //     if (!this.sessionId || this.alwaysRefreshSessionTokens) {
      //       this.getSessionId()
      //     } 

      //     body = {
      //       sid: this.sessionId,
      //       aams_ticket_id: this.aamsTicketId,
      //       aams_session_id: this.aamsSessionId,
      //       username: this.player.name,
      //       num_contract: this.player.name,
      //       session_amount: this.sessionAmountInCredits,
      //       game: this.selectedGame,
      //       mode: this.selectedMode,
      //       inactivity_time: 10,
      //       quality: this.selectedQualityValue,
      //       redirectUrl: `${this.gamingServiceEndpoint}/developer/launcher`

      //     }
      //   } else {
      //     body = {
      //       game: this.selectedGame,
      //       mode: this.selectedMode,
      //       inactivity_time: 10,
      //       session_amount: this.sessionAmountInCredits,
      //       quality: this.selectedQualityValue,
      //       redirectUrl: `${this.gamingServiceEndpoint}/developer/launcher`
      //     }
      //   }

      //   axios.post(`${this.gamingServiceEndpoint}/api/gameUrl`,
      //     body,
      //     {
      //       headers: {
      //         'x-access-token': this.accessToken
      //       }
      //     })
      //     .then((response) => {
      //       if (response.status === 200) {
      //         this.gameUrl = response.data.url
      //         this.parameters = response.data.url.split('?')[1]

      //         localStorage.player = JSON.stringify(this.player)
      //         localStorage.selectedGame = this.selectedGame
      //       }
      //     })
      //     .catch(error => {
      //       this.error = error.response.data
      //     })
      // },
      async getGameUrl(){
        let responseData
        console.log("---- getGameUrl ----")
        console.log("casinoName: "+ this.selectedCasino.name +
        " / gameName: " +this.selectedGame.gameName +
        " / selectedGameVersion: "+this.selectedGameVersion.code +
        " / selectedLanguage: "+this.selectedLanguage +
        " / selectedCurrency: "+this.selectedCurrency +
        " / selectedMode: "+this.selectedMode +
        " / selectedGameQualityValue: "+this.selectedQualityValue)

        //   this.clearError()
        this.gameUrl = ''
        this.parameters = ''
        let body

        if (this.selectedMode === 'live') { 
          if(this.selectedCasino.providerTypeId!='5'){
            console.log("---- Mode:live / ProviderType:GAI ----")
            if (!this.aamsTicketId || this.alwaysRefreshSessionTokens) {
              this.getAmmsTicketId()
            }
            if (!this.aamsSessionId || this.alwaysRefreshSessionTokens) {
              this.getAmmsSessionId()
            }
          }
          
          if (!this.sessionId || this.alwaysRefreshSessionTokens) {
            this.getSessionId()
          } 
          if(this.selectedCasino.providerTypeId!='5'){
            console.log("---- body GAI ----")
            body = {
              aams_session_id: this.aamsSessionId,
              aams_ticket_id: this.aamsTicketId,
              game: this.selectedGame.gameName,
              inactivity_time: 10,
              mode: this.selectedMode,
              num_contract: this.currentUser.name,
              quality: this.selectedQualityValue,
              redirectUrl: `${this.gamingServiceEndpoint}/developer/launcher`,
              session_amount: this.sessionAmountInCredits,
              sid: this.sessionId,
              username: this.currentUser.name
            }
          } else{
            console.log("------- Mode:live / ProviderType:International ----")
            console.log("---- body International ----")
            body = {
              balance: this.sessionAmountInCredits,
              game: this.selectedGame.gameName,
              gameVersion: this.selectedGameVersion.code,
              language: this.selectedLanguage,
              currency: this.selectedCurrency,
              quality: Number(this.selectedQualityValue),
              redirectUrl: `${this.gamingServiceEndpoint}/developer/launcher`,
              sessionId: this.sessionId,
              type: 'real',
              username: this.currentUser.name
            }
          }
        } else {
          if(this.selectedCasino.providerTypeId!='5'){
            console.log("---- Mode:fun / ProviderType:GAI ----")
            body = {
              game: this.selectedGame.gameName,
              inactivity_time: 10,
              mode: this.selectedMode,
              session_amount: this.sessionAmountInCredits,
              quality: this.selectedQualityValue,
              // redirectUrl: `${this.gamingServiceEndpoint}/developer/launcher`
            }
          }else{
            console.log("------- Mode:fun / ProviderType:International ----")
            body = {
              balance: this.sessionAmountInCredits,
              game: this.selectedGame.gameName,
              gameVersion: this.selectedGameVersion.code,
              language: this.selectedLanguage,
              currency:this.selectedCurrency.name,
              quality: Number(this.selectedQualityValue),
              type: this.selectedMode,
              username: this.currentUser.name
            }
          }
          
        }
        
        console.log(body)

        if(this.selectedCasino.providerTypeId!='5'){
          responseData = await casinoService.postGameUrl(this.selectedCasino.id, body)
        }
        else {
          responseData = await casinoService.internationalPostGameUrl(this.selectedCasino.id, body)
        }
        this.gameUrl = responseData
        console.log("--- gameUrl ---")
        console.log(this.gameUrl)
        this.parameters = responseData.split('?')[1]
        console.log("--- parameters ---")
        console.log(this.parameters)
        // localStorage.player = JSON.stringify(this.currentUser)
        // localStorage.selectedGame = this.selectedGame
      },
      
      generateQrCode(url) {
        let content = qr.imageSync(url, {
          size: 3,
          margin: 2
        })

        return URL.createObjectURL(new Blob([content.buffer], {type: 'image/png'}))
      },
      copyURL() {
        let parameters = document.getElementById('fetchUrlInput')
        parameters.select()
        parameters.setSelectionRange(0, 99999) //for mobile

        document.execCommand('copy')
      },
      copyLocalUrl() {
        let parameters = document.getElementById('localServerUrlInput')
        parameters.select()
        parameters.setSelectionRange(0, 99999) //for mobile

        document.execCommand('copy')
      },
      copyParameters() {
        let parameters = document.getElementById('gameUrlParametersInput')
        parameters.select()
        parameters.setSelectionRange(0, 99999) //for mobile

        document.execCommand('copy')
      },
      // getGamesAvailable() {
      //   axios.get(`${this.gamingServiceEndpoint}/games/active`, {
      //     headers: {
      //       'x-access-token': this.accessToken
      //     }
      //   })
      //   .then(response => {
      //     const gamesList = response.data.games
      //     this.gamesAvailable = Array.from(new Set(gamesList.map(
      //       (game) => {
      //         if (game.is_active && game.in_storage) {
      //           return game.name
      //         }
      //       }
      //     )))

      //   })
      //   .catch(error => {
      //     this.error = error.response.data
      //   })
      // },
    
      updateLocalServerUrl(url) {
        console.log("update-----")
        this.localServerUrl = url
      },
      updateQrCodeShow(show) {
        this.showQrCode = JSON.parse(show)
      },
      updateRefreshSessionTokens(alwaysRefresh) {
        this.alwaysRefreshSessionTokens = JSON.parse(alwaysRefresh)
      },
      // fixViewportDimensions() {
      //   let vh = document.documentElement.clientHeight * 0.01
      //   let vw = document.documentElement.clientWidth * 0.01

      //   document.documentElement.style.setProperty('--vh', `${vh}px`)
      //   document.documentElement.style.setProperty('--vw', `${vw}px`)
      // }
    },
    
  }
</script>

<style scoped>

</style>
